<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    :class="bgColorClass"
  >
    <v-row no-gutters
      class="my-5 px-4"
    >
      <v-col cols="12">
        <h1 class="pa-3 rounded-t">{{ pageData.title }}</h1>
        <div class="pa-4 rounded-b"
          v-html="pageData.content_html"
        ></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************** Script *************** -->
<!-- ************************************* -->
<script>
import { LAYZLOAD_IMG } from '@/literals.js';
import { ApiTool, AlertApiError } from '@/module.js';

export default {
  components: {
  },

  props: {
    siteApi: {
      type: String,
      required: true
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    menus: {
      type: Array,
      required: true,
    },
  },

  //***************************************************
  // Data
  //***************************************************
  data() {
    return {
      pageData: {},
      lazyload_img: LAYZLOAD_IMG,
      apiTool: new ApiTool(this.siteApi, this.siteData),
    }
  },

  //***************************************************
  // Computed
  //***************************************************
  computed: {
    bgColorClass() {
      if (this.setting.site_bg_image_url) {
        return ''
      }
      else if (this.setting.is_dark_theme) {
        return 'secondary darken-1'
      }
      else {
        return 'secondary lighten-1'
      }
    }
  },

  //***************************************************
  // Life cycle
  //***************************************************
  created() {
    console.log(this.$route.param.id);
    this.getContent()
  },

  //***************************************************
  // Methods
  //***************************************************
  methods: {
    // API req: コンテンツデータ
    getContent() {
      this.apiTool.getRequest('custom-page/' + this.$route.param.id + '/').then( data => {
        console.log(data);
        this.pageData = data
      })
      .catch(error => {
        AlertApiError(error)
      })
    },
  }
}
</script>

<style scoped>
</style>
